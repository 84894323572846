// 👇 It's important to not import @valu/react-valu-search directly here but
// only the subpackage `lazy`.  This ensures the main entry bundle won't get
// bloated by RVS.
import { LazyValuSearch, select } from "@valu/react-valu-search/lazy";

const loader = new LazyValuSearch({
    // import the file exporting the ValuSearch instance as the default export
    load: () => import("./search-ui"),
});

/**
 * INSTRUCTIONS:
 * This loader.tsx and valu-search.tsx are enough to connect valu search
 * to a button that open search. RVS will open in full screen modal.
 *
 * If you need to open search in partial modal, or customize the search,
 * check the docs in README.
 */

loader.init(() => {
    const button = select(".header-primary-search-toggle", HTMLButtonElement);
    // RVS provides small wrapper to the document.querySelector() which will
    // assert that the returned element is what you expect
    const input = select(".search-form__field", HTMLInputElement);

    input.addEventListener(
        "focus",
        () => {
            // Start lazy loading when the input is focused
            loader.load();
        },
        false,
    );

    button.addEventListener(
        "click",
        () => {
            // The activate() method lazy loads the valu-search.js file and
            // calls .activate() on the ValuSearch instance as well as soon as
            // possible. On subsequent calls when the module is already loaded it
            // just activates the VS instance.
            loader.activate();

            // Since the lazy loading might take a second we add a class that
            // visualizes the loading state via CSS if it is not loaded yet.
            if (!loader.isLoaded()) {
                button.classList.add("loading");
            }
        },
        false,
    );

    return vs => {
        // This function returned from the init callback will be called when the
        // lazy loading completes. The ValuSearch instance is passed to it which
        // can be used to bind inputs.
        vs.bindInput(input);
        // Once ready we can remove the loading indicator
        button.classList.remove("loading");
        vs.initModal();
    };
});
